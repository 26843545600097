<template>
    <RightDrawer :visible.sync="visible">
        <template slot="content">
            <ApprovalHeader :details="details"></ApprovalHeader>
            <a-divider />
            <a-descriptions>
                <a-descriptions-item :span="3" label="所属部门">{{details.department_name_self}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="申请客户">{{details.customer_name}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="联系方式">{{details.contact_phone}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="门店地址">{{details.customer_address}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="合约时间">{{details.term}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="原客户">{{details.old_customer}}</a-descriptions-item>
            </a-descriptions>
            <a-divider />
            <div class="drawer_title">审批进度</div>
           <ApprovalSpeed :list="details_flow.flow.approval_user"></ApprovalSpeed>
            <div class="drawer_title" v-if="details_flow.cc_user.length">抄送</div>
            <ApprovalCc :list="details_flow.cc_user"></ApprovalCc>
        </template>
    </RightDrawer>
</template>

<script>
import RightDrawer from "@/components/RightDrawer";
import { leaveInfo } from '@/api/approval'
import ApprovalHeader from "@/components/ApprovalHeader";
import ApprovalSpeed from "@/components/ApprovalSpeed";
import ApprovalCc from "@/components/ApprovalCc";
	import {
		getApprovalDetail,
	} from "@/api/evection.js";
export default {
    props: {
		
    },
    components: {
        RightDrawer,
        ApprovalHeader,
		ApprovalSpeed,
		ApprovalCc,
    },
    data() {
        return {
            visible: false,
			details: { },
			details_flow:{flow:{},cc_user:[]},
			
        }
    },
    created() {

    },
    methods: {
        get_details(record) {
			getApprovalDetail({
                data: {
                    id: record.approval_id,
					type:'binding_contract'
                }
            }).then(res => {
                let data = res.data.list;
                this.details = data.info_data;
				 this.details_flow = data.flow;
                this.visible = true;
			})
        },
    }
}
</script>